// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #1976D2;
$secondary : #26A69A;
$accent    : #9C27B0;

$dark      : #1D1D1D;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;
$init      : auto;

@mixin tablet {
  @media all and (max-width: 1023.98px) {
    @content;
  }
}
@mixin mobile {
  @media all and (max-width: 575.98px) {
    @content;
  }
}

.btn {
  transition: .2s !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn__primary {
  font-family: 'Benzin-Semibold' !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #000000 !important;

  background-image: linear-gradient(270deg, #00FEC1 0%, #00EFFE 100%) !important;
  background-color: #00EFFE !important;
  &:hover {
    background-image: linear-gradient(270deg, #21FFA2 0%, #41BBFF 100%);
  }
}

.btn__md {
  padding: 24px 40px !important;
  border-radius: 40px !important;
  @include mobile {
    padding: 20px 36px !important;
  }
}
